import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

// Material Core Components
import { Button, Container, IconButton, Typography } from "@material-ui/core";

// Custom Components
import RouteSearch from "./LPNavbar/RouteSearch";

// Custom Styles
import useLandingpageStyles from "assets/jss/pages/landingpageStyle";
import { FooterDiv } from "./LPNavbar/style";

const LPFooter = () => {
  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary">
        {"© "}
        Linguists Collective Ltd<sup>®</sup> {new Date().getFullYear()}
      </Typography>
    );
  }

  const classes = useLandingpageStyles();
  return (
    <Fragment>
      <footer
        className={classes.footer}
        style={{
          color: "white",
          fontWeight: "700",
          backgroundImage: "linear-gradient(120deg, #562EDE 0%, #9E36E1 100%",
        }}
      >
        <FooterDiv>
          <div className="left">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                textAlign: "center",
              }}
            >
              <p className="text">Linguists Collective</p>
              <Link
                style={{ textDecoration: "none" }}
                to="/landing-page/clients-portal"
              >
                <Button
                  style={{ color: "white", border: "1px solid white" }}
                  variant="outlined"
                >
                  Clients
                </Button>
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to="/landing-page/linguists-professionals"
              >
                <Button
                  style={{
                    marginTop: "1em",
                    color: "white",
                    border: "1px solid white",
                  }}
                  variant="outlined"
                >
                  Linguists and Professionals
                </Button>
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to="/landing-page/partner-organisation"
              >
                <Button
                  style={{
                    marginTop: "1em",
                    color: "white",
                    border: "1px solid white",
                  }}
                  variant="outlined"
                >
                  Partner Organisations
                </Button>
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to="/landing-page/employees-portal"
              >
                <Button
                  style={{
                    marginTop: "1em",
                    color: "white",
                    border: "1px solid white",
                  }}
                  variant="outlined"
                >
                  Employees
                </Button>
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to={{ pathname: "https://linguistscollective.info" }}
                target={"_blank"}
              >
                <Button
                  style={{
                    marginTop: "1em",
                    color: "white",
                    border: "1px solid white",
                  }}
                  variant="outlined"
                >
                  LC Agency
                </Button>
              </Link>
            </div>

            {/* Amended footer menu added, items and added header as instructed by Mr mamun (24/12/2020)  */}
            <div>
              <p>Site</p>
              <Link style={{ textDecoration: "none", color: "white" }} to="/">
                <p>Home</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/about"
              >
                <p>About Us</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/clients-portal"
              >
                <p>Book an Interpreter</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/clients-portal"
              >
                <p>Order Translation Online</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/add-new-customer"
              >
                <p>Request Free Quote</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/events"
                target="_self"
              >
                <p>Events</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{
                  pathname: "https://amartoko.com/shop/Language-Shop",
                }}
                target="_blank"
              >
                <p>LC Shop</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/subscribe-here"
              >
                <p>Subscribe</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{ pathname: "https://youtu.be/KWBLoS9fyLM " }}
                target="_blank"
              >
                <p>End Modern Slavery</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{ pathname: "https://linguistscollective.eu" }}
                target="_blank"
              >
                <p>Europe</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{ pathname: "https://linguistscollective.ca" }}
                target="_blank"
              >
                <p>Canada</p>
              </Link>
            </div>
            <div>
              <p>Services</p>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/interpreter"
              >
                <p>Interpreting</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/translation"
              >
                <p>Translation</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/bilingual-professional"
              >
                <p>Multilingual Professional</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/forensic-expert-evidence"
              >
                <p>Forensic & Expert Evidence</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/expert-language-solutions"
              >
                <p>Expert Language Solutions</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/post-job-advert"
              >
                <p>Job Advert and Search</p>
              </Link>
            </div>
            <div>
              <p>Customers</p>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/cash-client-login"
              >
                <p>Cash Client Login</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/customer-registration"
              >
                <p>Cash Client Registration</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/account-client-login"
              >
                <p>Billing Account Client Login</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/clientacc-opening-form"
              >
                <p>Billing Account Application</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{ pathname: "https://lcap.linguistscollective.net/login" }}
                target="_blank"
              >
                <p>LC Agency Customer Login</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/verify-linguist"
              >
                <p>Verify Linguist ID Card</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{
                  pathname:
                    "https://lsts.linguistscollective.net/verify-certificate",
                }}
                target="_blank"
              >
                <p>Verify Linguist Certificate</p>
              </Link>
            </div>

            <div>
              <p>Linguists</p>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/linguists-login"
              >
                <p>Linguist Marketplace Login</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{ pathname: "https://lcap.linguistscollective.net/login" }}
                target="_blank"
              >
                <p>Linguist Agency Login</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/linguist/registration"
                target="_blank"
              >
                <p>Linguist Marketplace Registration (Initial)</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/linguist-professionals/upload-cv"
              >
                <p>Drop Us Your CV</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{ pathname: "https://youtu.be/q1Jfi2iDgr4" }}
                target="_blank"
              >
                <p>How to Register</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{ pathname: "https://lcap.linguistscollective.net/login" }}
                target="_blank"
              >
                <p>Agency Linguist Expenses Claim</p>
              </Link>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={{ pathname: "https://linguistglossary.net" }}
                target="_blank"
              >
                <p>LC Glossary</p>
              </Link>
            </div>

            <div>
              <p>Partners</p>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/sppo/login"
              >
                <p>SPPO Login</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/sppor-form"
              >
                <p>SPPO Registration</p>
              </Link>
            </div>

            <div>
              <p>Legal</p>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/privacy-policy"
              >
                <p>Privacy Policy</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/terms-and-conditions"
              >
                <p>T&C</p>
              </Link>
            </div>

            <div>
              <p>Help</p>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/contact"
              >
                <p>Contact Us</p>
              </Link>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/landing-page/faq"
              >
                <p>FAQs</p>
              </Link>
            </div>
          </div>
          <div className="right">
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RouteSearch />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="https://www.linkedin.com/company/linguistscollective"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton>
                    <Icon style={{ color: "white" }} name="linkedin" />
                  </IconButton>
                </a>
                <a
                  href="https://www.facebook.com/LinguistsCollective/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton>
                    <Icon style={{ color: "white" }} name="facebook" />
                  </IconButton>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCy_sIqjGtj4wRFv8f45Qyig"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton>
                    <Icon style={{ color: "white" }} name="youtube" />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </FooterDiv>
      </footer>
      <footer className={classes.footer}>
        <div
          style={{
            width: "100%",
            margin: "auto",
            padding: "1rem 7rem 1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Container style={{ padding: "0px", margin: "0px" }} maxWidth="sm">
            <Copyright />
          </Container>
          <div className={classes.signature}>
            <p>
              Builder:
              {/* Signature by */}{" "}
              <a href="https://cambridgeitconsultancy.co.uk/">{"{CITC}"}</a>
            </p>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default LPFooter;
